<template>
  <div>
    <div :class="isCompleted ? 'green--text' : 'grey--text'">
      {{ teamName }}

      <span class="team-score">
        <template v-if="teamScore"> <b>-</b> {{ teamScore }} pts </template>
      </span>
    </div>
    <div>
      {{ lastPlay }}
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import Mission from "@shared/enums/Mission"
import { Role } from "@/helpers"

export default {
  name: "AuditTeamTag",
  props: {
    teamId: {
      type: String,
      required: true
    },
    team: {
      type: Object,
      required: true
    },
    missionPlaysArray: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      strikes: [],
      fineTunePoints: false
    }
  },
  computed: {
    ...mapGetters({
      mission: "getCurrentMission",
      playType: "missionPlayType",
      isUnlimited: "isUnlimitedMission"
    }),
    ...mapGetters([
      "nOfMissionTries",
      "missionHintsArray",
      "onlineUsersGroupedByTeam",
      "user",
      "styles",
      "gameStatus"
    ]),
    ...mapGetters("auth", ["isHost"]),
    teamScore() {
      return this.team?.totalScore + "pts"
    },
    lastPlay() {
      return this.plays?.[this.plays.length - 1]?.correct ?? ""
    },
    isTeamMission() {
      return this.playType === "team"
    },
    isGameOver() {
      if (this.mission.name == "Game Over") {
        return true
      } else {
        return false
      }
    },
    behavior() {
      return this.mission?.behavior
    },
    users() {
      if (!this.onlineUsersGroupedByTeam) return []
      return this.onlineUsersGroupedByTeam[this.teamId]
    },
    selectedUsers() {
      if (!this.users) return []
      return this.users.filter(({ selected }) => selected)
    },
    nOfSelectedUsers() {
      return this.selectedUsers.length
    },
    teamName() {
      return this.team?.name
    },
    teamScore() {
      return parseInt(this.team.totalScore || 0) || 0
    },
    answers() {
      // skip the computation for unlimited
      if (this.isUnlimited) return []
      return this.plays.reduce((acc, val) => {
        const { answer } = val
        if (Array.isArray(answer)) {
          return acc.concat(answer)
        } else {
          acc.push(answer)
          return acc
        }
      }, [])
    },
    isCompleted() {
      return this.isOutOfTries || this.isMissionCorrect
    },
    isOutOfTries() {
      return this.answers.length >= this.nOfMissionTries
    },
    plays() {
      if (this.mission?.behavior == "Two Truths Reveal") {
        return this.missionPlaysArray.filter(
          play =>
            play.teamID === this.teamId &&
            this.gameStatus.twoTruthsID == play.twoTruthsID
        )
      } else {
        return this.missionPlaysArray.filter(
          play => play.teamID === this.teamId
        )
      }
    },
    hints() {
      return this.missionHintsArray.filter(play => play.teamID === this.teamId)
    },
    nOfPlays() {
      return this.plays.length
    },
    nOfOfflinePlays() {
      return this.correctPlays.reduce((acc, val) => {
        const { userID } = val
        const offline = !this.selectedUsers.some(({ id }) => id === userID)
        return offline ? acc + 1 : acc
      }, 0)
    },
    nOfExpectedPlays() {
      // return this.nOfSelectedUsers + this.nOfOfflinePlays
      return this.nOfSelectedUsers
    },
    isMissionCorrect() {
      const {
        nOfSelectedUsers,
        nOfCorrectPlays,
        nOfPlays,
        nOfExpectedPlays,
        isFactMatch
      } = this

      if (isFactMatch) {
        return nOfPlays > 0
      } else if (nOfSelectedUsers > 1) {
        return nOfCorrectPlays > 0 && nOfCorrectPlays === nOfExpectedPlays
      } else {
        return nOfCorrectPlays > 0
      }
    },
    correctPlays() {
      return this.plays.filter(({ result }) => result)
    },
    nOfCorrectPlays() {
      return this.correctPlays.length
    },
    isFactMatch() {
      return [
        Mission.FactMatch,
        Mission.MatchGame,
        Mission.OrderTheCards
      ].includes(this.behavior)
    },
    nOfIncorrectPlays() {
      if (this.isFactMatch) return 0

      return this.plays.reduce((acc, val) => {
        const { result, answer } = val
        if (Array.isArray(answer)) {
          if (result) {
            return acc + answer.length - 1
          } else {
            return acc + answer.length
          }
        } else {
          if (result) {
            return acc
          } else {
            return acc + 1
          }
        }
      }, 0)
    },
    teamTagClassName() {
      const { isMissionCorrect, nOfExpectedPlays } = this
      return {
        "rtb-text-correct": isMissionCorrect && this.isCompleted,
        "rtb-text-wrong":
          (this.isOutOfTries || this.mission?.numOfTries == "Unlimited") &&
          !isMissionCorrect &&
          nOfExpectedPlays > 0 &&
          (this.nOfCorrectPlays === nOfExpectedPlays ||
            this.nOfIncorrectPlays >= nOfExpectedPlays)
      }
    },
    backgroundColor() {
      return this.teamTagClassName["rtb-text-correct"] === true
        ? "--correct-color"
        : this.teamTagClassName["rtb-text-wrong"] === true
        ? "--wrong-color"
        : "--primary-accent-color"
    },
    isAuditor() {
      return this.user?.role === Role.Audit
    }
  },
  methods: {
    toggleFineTunePoints() {
      this.fineTunePoints = !this.fineTunePoints
    },
    resetMissionTeam() {
      if (this.user.role == "facilitator") {
        var arr = this.plays
        for (var play in arr) {
          this.$store.dispatch("deletePlay", arr[play].id)
        }
        var arr = this.hints
        for (var play in arr) {
          this.$store.dispatch("deletePlay", arr[play].id)
        }
      }
    },
    increaseScore(amount) {
      console.log("increaseScore", this.team)
      if (this.isHost) {
        const obj = {}
        var num = this.team.totalScore + parseInt(this.mission.points / 2)
        if (amount) num = this.team.totalScore + amount
        if (num > 1000000) return
        if (isNaN(num)) return
        obj.teamScore = num
        obj.teamID = this.team.id
        this.$store.dispatch("setTeamScore", obj)
      }
    },
    decreaseScore(amount) {
      if (this.isHost) {
        const obj = {}
        var num = this.team.totalScore - parseInt(this.mission.points / 2)
        if (amount) num = this.team.totalScore - amount
        if (num < 0) return
        if (isNaN(num)) return
        obj.teamScore = num
        obj.teamID = this.team.id
        this.$store.dispatch("setTeamScore", obj)
      }
    }
  }
}
</script>

<style lang="scss">
.team-score {
  filter: brightness(1.75);
}
</style>
