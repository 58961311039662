var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      staticClass: "game-card audit-card",
      class: { selected: _vm.isSelected },
      attrs: { "d-flex": "" },
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            {
              staticClass: "game-header",
              attrs: { shrink: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.onViewGame.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "game-title-wrap", attrs: { "d-flex": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "game-header-top" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { column: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        "d-flex": "",
                                        "align-center": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            row: "",
                                            "align-center": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticStyle: { "min-width": "0" },
                                              attrs: {
                                                xs11: "",
                                                "d-flex": "",
                                                grow: "",
                                              },
                                            },
                                            [
                                              _c("h2", [
                                                _vm._v(_vm._s(_vm.gameName)),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs1: "" } },
                                            [
                                              _vm.favorite
                                                ? _c("SvgIcon", {
                                                    attrs: {
                                                      name: "heart",
                                                      width: "20",
                                                      height: "20",
                                                    },
                                                    on: {
                                                      click: _vm.toggleFavorite,
                                                    },
                                                  })
                                                : _c("SvgIcon", {
                                                    attrs: {
                                                      name: "heart-selected",
                                                      width: "20",
                                                      height: "20",
                                                    },
                                                    on: {
                                                      click: _vm.toggleFavorite,
                                                    },
                                                  }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { staticClass: "game-details" },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _vm.game.started
                                            ? _c("v-flex", [
                                                _vm._v(" Started "),
                                              ])
                                            : _c("v-flex", [
                                                _vm._v(" NOT started "),
                                              ]),
                                          _vm.isStandard
                                            ? _c(
                                                "v-flex",
                                                {
                                                  staticClass: "host-name",
                                                  class: {
                                                    "actual-host":
                                                      !!_vm.actualHost,
                                                  },
                                                  attrs: {
                                                    "text-xs-right": "",
                                                  },
                                                },
                                                [
                                                  !!_vm.actualHost
                                                    ? _c("div", [
                                                        _vm._v(
                                                          " Current Host: "
                                                        ),
                                                        _c(
                                                          "strong",
                                                          [
                                                            _c("UserName", {
                                                              attrs: {
                                                                firstname:
                                                                  _vm.host
                                                                    .firstname,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isStandard && !!_vm.actualHost
                    ? _c(
                        "v-flex",
                        {
                          staticClass: "host-icon-wrap",
                          attrs: { shrink: "" },
                        },
                        [
                          _c("div", { staticClass: "host-icon" }, [
                            _c("img", {
                              staticClass: "host-image",
                              attrs: { src: _vm.host.image },
                              on: { click: _vm.editGame },
                            }),
                          ]),
                        ]
                      )
                    : _c("v-flex"),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "game-card-body", attrs: { "d-flex": "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "game-card-body-row",
                      attrs: { shrink: "" },
                    },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "game-card-players" },
                        [
                          _vm.isStandard
                            ? _c(
                                "v-layout",
                                { attrs: { row: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        shrink: "",
                                        "d-flex": "",
                                        "align-center": "",
                                        shink: "",
                                      },
                                    },
                                    [_vm._v(" Players ")]
                                  ),
                                  !!_vm.game.players
                                    ? _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "align-center": "",
                                            "d-flex": "",
                                            shrink: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "players-count" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "player-count-number",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.nOfPlayersOnline)
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" of "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "player-count-number",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.game.players)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "align-center": "",
                                            "d-flex": "",
                                            shrink: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "players-count" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "player-count-number",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.nOfPlayersOnline)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              )
                            : _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        hrink: "",
                                        "d-flex": "",
                                        "align-center": "",
                                        shink: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editGame()
                                        },
                                      },
                                    },
                                    [_vm._v(" Everyone ")]
                                  ),
                                ],
                                1
                              ),
                          _c(
                            "v-layout",
                            { attrs: { row: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _vm.game.helpRequested
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "red",
                                          attrs: {
                                            shrink: "",
                                            "d-flex": "",
                                            "align-center": "",
                                            shink: "",
                                          },
                                        },
                                        [_vm._v(" HELP REQUESTED ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-flex",
                                    {
                                      class:
                                        _vm.scribes == 0 ? "red--text" : "",
                                      attrs: {
                                        shrink: "",
                                        "d-flex": "",
                                        "align-center": "",
                                        shink: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Scribes: " + _vm._s(_vm.scribes) + " "
                                      ),
                                    ]
                                  ),
                                  false
                                    ? _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            shrink: "",
                                            "d-flex": "",
                                            "align-center": "",
                                            shink: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Started: " +
                                              _vm._s(_vm.startTime) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        shrink: "",
                                        "d-flex": "",
                                        "align-center": "",
                                        shink: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { on: { click: _vm.toggleAutopilot } },
                                        [
                                          _vm._v(
                                            " AutoPilot: " +
                                              _vm._s(
                                                _vm.game.autopilot
                                                  ? "On"
                                                  : "Off"
                                              )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        shrink: "",
                                        "d-flex": "",
                                        "align-center": "",
                                        shink: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.missionName) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        shrink: "",
                                        "d-flex": "",
                                        "align-center": "",
                                        shink: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " ( " +
                                          _vm._s(_vm.currentMissionPos) +
                                          " / " +
                                          _vm._s(_vm.missionsArray.length - 1) +
                                          " ) "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        shrink: "",
                                        "d-flex": "",
                                        "align-center": "",
                                        shink: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.currentMissionTime) +
                                          " Seconds "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        shrink: "",
                                        "d-flex": "",
                                        "align-center": "",
                                        shink: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Hints: " +
                                          _vm._s(_vm.hintsTaken) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "align-right": "" } },
                                [
                                  _vm._v(" TEAMS"),
                                  _c("br"),
                                  _vm._l(_vm.teams, function (team, i) {
                                    return _c(
                                      "div",
                                      { key: team.id },
                                      [
                                        _c("AuditTeamTag", {
                                          attrs: {
                                            mode: "play",
                                            teamId: i,
                                            missionPlaysArray:
                                              _vm.missionPlaysArray,
                                            team: team,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c("AuditCountdown", {
                            ref: "countdown",
                            staticStyle: { top: "4px" },
                            attrs: { endTime: _vm.endTime },
                          }),
                          _c("br"),
                          _vm.isHost
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { small: "" },
                                  on: { click: _vm.enterGame },
                                },
                                [_vm._v("Enter")]
                              )
                            : _vm._e(),
                          _vm.isAudit
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { small: "" },
                                  on: { click: _vm.enterGame },
                                },
                                [_vm._v("Audit")]
                              )
                            : _vm._e(),
                          _vm.isHost
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { small: "" },
                                  on: { click: _vm.nextMission },
                                },
                                [_vm._v("Next")]
                              )
                            : _vm._e(),
                          _vm.isHost
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { small: "", width: "10" },
                                  on: { click: _vm.stageGame },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        !_vm.game.ondeck ? "Stage" : "UnStage"
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.isHost
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { fab: "", small: "" },
                                  on: { click: _vm.addTime },
                                },
                                [
                                  _c("v-icon", { attrs: { dark: "" } }, [
                                    _vm._v("plus_one"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !!_vm.game && _vm.editGameDialog
            ? _c(
                "v-dialog",
                {
                  attrs: {
                    persistent: "",
                    "no-click-animation": "",
                    width: "60%",
                  },
                  model: {
                    value: _vm.editGameDialog,
                    callback: function ($$v) {
                      _vm.editGameDialog = $$v
                    },
                    expression: "editGameDialog",
                  },
                },
                [
                  _c("GameSettings", {
                    attrs: { game: _vm.game },
                    on: { close: _vm.closeEdit },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }