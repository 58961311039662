var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "scoreboard-card", attrs: { "d-flex": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "game-card-body", attrs: { "d-flex": "" } },
            [
              _c(
                "v-layout",
                { staticClass: "game-card-layout", attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "card-scoreboard", attrs: { shrink: "" } },
                    [_vm._v("OVERSEE GAMES")]
                  ),
                  _c(
                    "v-flex",
                    {
                      staticStyle: {
                        "overflow-y": "auto",
                        "max-height": "80vh",
                      },
                      attrs: { "d-flex": "" },
                    },
                    [
                      _c("RecycleScroller", {
                        staticClass: "scoreboard-card__recycle-scroller",
                        attrs: {
                          items: _vm.groups,
                          "item-size": 380,
                          "key-field": "id",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-layout",
                                  { attrs: { row: "" } },
                                  _vm._l(item.games, function (game, i) {
                                    return _c("AuditCard", {
                                      key: game.name + "-" + i,
                                      staticClass:
                                        "scoreboard-card__audit-game xs4",
                                      attrs: {
                                        game: game,
                                        favorite: game.favorite,
                                      },
                                      on: {
                                        onEnterGame: _vm.onEnterGame,
                                        toggleFavorite: _vm.toggleFavorite,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }