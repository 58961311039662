<template>
  <v-layout row class="audit-countdown">
    <v-flex d-flex>
      <countdown
        ref="countdown"
        :interval="1000"
        :time="endTime"
        @progress="trackProgress"
        @end="onCountDownEnded"
      />
      <div class="timer" ref="timer">
        <span>{{ time }}</span>
      </div>
    </v-flex>
    <v-flex class="additional-seconds-input" v-if="showInput">
      <v-text-field
        v-model="additionalSeconds"
        solo
        placeholder="+ secs"
        hide-details
        @keyup.enter="updateTimerSeconds"
        ref="additionalSeconds"
        @blur="handleInputBlur"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import TimesUpSound from "@/assets/sounds/timesup.mp3"
export default {
  name: "AuditCountdown",
  props: {
    endTime: {
      required: true,
      type: Number
    },
    pregame: {
      type: Boolean,
      default: false
    },
    autopilot: {
      type: Boolean,
      default: false
    },
    rushThemUp: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      time: null,
      showInput: false,
      additionalSeconds: null,
      firstTime: false,
      isPlayingSound: false
    }
  },
  watch: {
    showInput(value) {
      if (value) {
        setTimeout(() => {
          this.$refs?.additionalSeconds?.focus()
        }, 300)
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost"]),
    ...mapGetters("soundeffect", ["volume"]),
    ...mapGetters(["gameStatus"]),
    ...mapGetters({
      missionID: "currentMission",
      mission: "getCurrentMission"
    })
  },
  methods: {
    start() {
      console.log("Starting countdown from " + this.endTime)
      this.$refs.countdown.start()
    },
    reset() {
      this.$refs.timer.classList.remove("activated")
      this.$refs.timer.classList.remove("late")
    },
    trackProgress({ totalHours, minutes, seconds, totalSeconds }) {
      const node = this.$refs.timer

      let time = ""
      if (this.autopilot) {
        if (totalSeconds === 60) {
          this.$bus.$emit("audit-countdown-warning", "One minute")
        }
      }
      if (this.pregame) {
        time = `${totalHours}:${minutes}:${seconds}`
      } else {
        if (node) {
          if (totalSeconds > 0) {
            node.classList.add("activated")
            node.classList.remove("late")
          }
          if (totalSeconds <= 5 && this.rushThemUp === true) {
            if (totalSeconds == 0) {
              node.classList.remove("alert")
              if (node.classList.contains("activated")) {
                node.classList.add("late")
                node.classList.remove("activated")
              }
            } else {
              node.classList.add("alert")
            }
          } else {
            node.classList.remove("alert")
            node.classList.remove("late")
          }
        }

        if (seconds < 10) {
          time = "0" + seconds
        } else {
          time = seconds
        }

        if (minutes > 10) {
          time = minutes + ":" + time
        } else if (minutes > 0) {
          time = "0" + minutes + ":" + time
        }
      }

      this.time = time
    },
    onCountDownEnded() {
      this.$bus.$emit("audit-countdown-ended", this.endTime)
      this.trackProgress({
        totalSeconds: 0,
        totalHours: 0,
        seconds: 0,
        minutes: 0
      })
    },
    toggleInput() {
      if (!this.isHost) return
      this.showInput = !this.showInput
    },
    async updateTimerSeconds() {
      const milliSeconds = parseInt(this.additionalSeconds) * 1000
      if (milliSeconds) {
        const gameEndTime = parseInt(this.gameStatus.endTime) || 0
        const endTime = gameEndTime - Date.now()
        const time = endTime > 0 ? endTime : 0
        await this.$store.dispatch("updateGameStatusAny", {
          endTime: Date.now() + time + milliSeconds
        })
      }
      this.showInput = false
      this.additionalSeconds = null
    },
    handleInputBlur() {
      setTimeout(() => {
        this.showInput = false
      }, 1000)
    }
  }
}
</script>

<style lang="scss">
.audit-countdown {
  .timer {
    text-align: left;
    height: 20px;
    line-height: 27px;
    font-size: 25px;
    margin-left: -8px;
    color: green;
    cursor: pointer;
    span {
      position: relative;
    }
    &::before {
      content: "";
      width: 108%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    &.alert span {
      display: block;
      animation-duration: 1.25s;
      animation-iteration-count: infinite;
      animation-name: alert;
    }
    &.late span {
      color: $color-wrong;
      animation-duration: 1.2s;
      animation-name: late;
      animation-fill-mode: forwards;
    }
  }

  .additional-seconds-input {
    margin-left: 14px;
    border-radius: 2px;
    .v-text-field.v-text-field--solo .v-input__control {
      min-height: 35%;
      padding: 0;
      height: 22px;
      max-height: 30px;
      max-width: 74px;
      margin-top: 3px;
      .v-input__slot {
        padding: 0 5px;
      }
    }
  }

  @keyframes alert {
    0% {
      transform: scale(1);
      color: $color-grey-light1;
    }
    50% {
      transform: scale(1.05);
      color: $color-wrong;
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes late {
    0% {
      color: $color-wrong;
    }
    7% {
      color: $color-wrong;
    }
    14% {
      color: $color-wrong;
    }
    21% {
      color: $color-wrong;
    }
    28% {
      color: $color-wrong;
    }
    35% {
      color: $color-wrong;
    }
    42% {
      color: $color-wrong;
    }
    49% {
      color: $color-white;
    }
    56% {
      color: $color-wrong;
    }
    64% {
      color: $color-wrong;
    }
    71% {
      color: $color-wrong;
    }
    78% {
      color: $color-wrong;
    }
    85% {
      color: $color-wrong;
    }
  }
}
</style>
