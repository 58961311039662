

























































































































































































// @ts-nocheck
import { mapGetters, mapActions, mapState } from "vuex"
import moment from "moment"

import User from "@shared/User"
import { db } from "@/firebase"
import SvgIcon from "@/components/base/SvgIcon.vue"
import NavigationService from "@/services/navigation.service"

import UserName from "@/components/GroupTeams/Common/User/UserName.vue"
const GameSettings = () => import("@/components/Game/GameSettings.vue")
import AuditCountdown from "@/components/GroupTeams/Common/AuditCountdown"
import { Role } from "../helpers"
import AuditTeamTag from "@/components/GroupTeams/Common/AuditTeamTag"

const SELECT_RETRY_TIMEOUT = 10000 // 10 sec
const PROFILE_ICON_URL = require("@/assets/game-card-profile-icon.png")

export default {
  name: "AuditCard",
  components: {
    UserName,
    GameSettings,
    AuditCountdown,
    AuditTeamTag,
    SvgIcon
  },
  props: {
    game: {
      type: Object,
      required: true
    },
    favorite: Boolean,
    selected: String,
    isPlayedByUser: Boolean,
    isUnlockedByUser: Boolean
  },

  data() {
    return {
      profileIconUrl: PROFILE_ICON_URL,
      assignedHost: null,
      time: null,
      editGameDialog: false,
      loading: false,
      loadingTimeout: null,
      users: {},
      gameStatus: {},
      plays: {},
      missions: {},
      teams: {}
    }
  },
  beforeDestroy() {
    clearTimeout(this.loadingTimeout)
    this.assignedHostRef?.off("value", this.onHostUpdate)
    this.usersRef?.off("value", this.onUsersUpdate)
    this.teamsRef?.off("value", this.onTeamsUpdate)
    this.gameStatusRef?.off("value", this.onGameStatusUpdate)
    this.playsRef?.off("value", this.onPlaysUpdate)
    this.missionsRef?.off("value", this.onPlaysUpdate)
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    toggleFavorite() {
      this.$emit("toggleFavorite", this.game.id)
    },
    addTime() {
      if (!this.isHost) return
      const MINUTES_TO_ADD = 1
      const newGame = { ...this.game }
      newGame.gameTime = newGame.gameTime + 1000 * 60 * MINUTES_TO_ADD
      newGame.started = false
      newGame.startTimestamp =
        newGame.startTimestamp + 1000 * 60 * MINUTES_TO_ADD
      newGame.theKey = this.game.id
      this.$store.dispatch("Games/updateGame", newGame)
    },
    enterGame() {
      this.$emit("onEnterGame", { gameID: this.game.theKey })
    },
    nextMission() {
      if (!confirm("Okay to advance the room to the next mission?")) return
      const { gameStatus: status, missionsArray: missions, orgID, game } = this
      const currentID = status?.current_mission?.id
      const index = missions.findIndex(mission => mission.id === currentID)
      const nextID = missions[index + 1]?.id
      if (!nextID) alert("There are no more missions left.")
      NavigationService.navigateTo(orgID, game?.id, nextID)
    },
    onHostUpdate(snapshot) {
      this.assignedHost = snapshot.val()
    },
    onGameStatusUpdate(snapshot) {
      this.gameStatus = snapshot.val() || {}
    },
    onPlaysUpdate(snapshot) {
      this.plays = snapshot.val() || {}
    },
    onUsersUpdate(snapshot) {
      this.users = snapshot.val() || {}
    },
    onMissionsUpdate(snapshot) {
      this.missions = snapshot.val() || {}
    },
    onTeamsUpdate(snapshot) {
      this.teams = snapshot.val() || {}
    },
    stageGame() {
      if (!this.isHost) return
      let obj = {}
      obj.theKey = this.game.id
      obj.ondeck = !this.game.ondeck
      this.updateGameAny(obj)
    },
    toggleDectivate(e) {
      if (!this.isHost) return
      if (e.shiftKey) {
        let obj = {}
        obj.theKey = this.game.id
        obj.ondeck = !this.game.ondeck
        this.updateGameAny(obj)
      } else {
        let obj = {}
        obj.theKey = this.game.id
        obj.deactivate = !this.game.deactivate
        this.updateGameAny(obj)
      }
    },
    toggleAutopilot() {
      let obj = {}
      obj.theKey = this.game.id
      obj.autopilot = !this.game.autopilot
      this.updateGameAny(obj)
    },
    closeEdit() {
      this.editGameDialog = false
    },
    editGame() {
      if (!this.isHost) return
      this.editGameDialog = true
    },
    addTimeToGame() {
      this.$emit("addTimeToGame", this.game.id)
    },
    removeTimeToGame() {
      this.$emit("removeTimeToGame", this.game.id)
    },
    onUserSelect({ userID }) {
      this.$emit("onUserSelect", { userID })
    },
    onViewGame() {
      if (this.isSelected === false) {
        this.$emit("onSelect", { gameID: this.game.id })

        if (this.loading === false) {
          this.loading = true

          // if `isSelected` do not changes in SELECT_RETRY_TIMEOUT – reset loading state
          // to give user an ability to try again
          this.loadingTimeout = setTimeout(() => {
            this.loading = false
          }, SELECT_RETRY_TIMEOUT)
        }
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "isHost", "isAudit", "clientID"]),
    ...mapState("group", ["modes"]),
    ...mapGetters(["orgID"]),
    missionName() {
      return this.gameStatus?.current_mission?.name
    },
    currentMission() {
      return this.gameStatus?.current_mission
    },
    currentMissionTime() {
      return this.currentMission?.time
    },
    currentMissionPos() {
      const currentID = this.currentMission?.id
      return this.missionsArray.findIndex(mission => mission.id === currentID)
    },
    endTime() {
      const int = parseInt(this.gameStatus.endTime) || 0
      const endTime = int - Date.now()
      return endTime > 0 ? endTime : 0
    },
    startTime() {
      return moment(this.game.startTimestamp).format("LTS")
    },
    usersRef() {
      return db
        .auxiliary()
        .ref(`org/1/users`)
        .orderByChild("gameID")
        .equalTo(this.game?.id)
    },
    gameStatusRef() {
      return db
        .auxiliary()
        .ref(`org/${this.orgID}/game/${this.game?.id}/gameStatus`)
    },
    hintsTaken() {
      return this.missionPlaysArray.filter(play => play.hint).length
    },
    missionPlaysArray() {
      const currentID = this.currentMission?.id
      return Object.values(this.plays).filter(
        play => play.missionID === currentID
      )
    },
    missionsArray() {
      return Object.values(this.missions).sort((a, b) => a.pos - b.pos)
    },
    missionsRef() {
      return db
        .auxiliary()
        .ref(`org/${this.orgID}/game/${this.game.id}/missions`)
    },
    teamsRef() {
      return db.auxiliary().ref(`org/${this.orgID}/game/${this.game.id}/teams`)
    },
    playsArray() {
      return Object.values(this.plays || {})
    },
    playsRef() {
      return db.auxiliary().ref(`org/${this.orgID}/game/${this.game.id}/play`)
    },
    actualHost() {
      return this.usersOnline.find(({ role }) => role === Role.Host)
    },
    assignedHostID() {
      return this.game?.hostUserID
    },
    assignedHostRef() {
      return this.assignedHostID
        ? db.auxiliary().ref(`org/1/users/${this.assignedHostID}`)
        : null
    },
    gameName() {
      return this.game?.externalName || this.game?.name
    },
    isStandard() {
      return (
        !this.game.gameType ||
        this.game.gameType == "Standard" ||
        this.game.gameType == "Green Room" ||
        !!this.streamUrl
      )
    },
    isSelected() {
      return this.selected == this.game.id
    },
    usersOnline() {
      return User.normalize(this.users).filter(this.$store.getters.isUserAlive)
    },
    playersOnline() {
      return this.usersOnline.filter(({ role }) => role === Role.Player)
    },
    nOfPlayersOnline() {
      return this.playersOnline.length
    },
    scribes() {
      return this.playersOnline.filter(user => user.selected).length
    },
    host() {
      return this.actualHost || this.assignedHost
    },
    streamUrl() {
      return this.game?.streamUrl
    }
  },
  watch: {
    usersRef: {
      handler(newValue, oldValue) {
        if (oldValue) {
          oldValue.off("value", this.onUsersUpdate)
          this.users = {}
        }
        if (newValue) {
          newValue.on("value", this.onUsersUpdate)
        }
      },
      immediate: true
    },
    missionsRef: {
      handler(newValue, oldValue) {
        if (oldValue) {
          oldValue.off("value", this.onMissionsUpdate)
          this.users = {}
        }
        if (newValue) {
          newValue.on("value", this.onMissionsUpdate)
        }
      },
      immediate: true
    },
    teamsRef: {
      handler(newValue, oldValue) {
        if (oldValue) {
          oldValue.off("value", this.onTeamsUpdate)
          this.users = {}
        }
        if (newValue) {
          newValue.on("value", this.onTeamsUpdate)
        }
      },
      immediate: true
    },
    playsRef: {
      handler(newValue, oldValue) {
        if (oldValue) {
          oldValue.off("value", this.onPlaysUpdate)
          this.users = {}
        }
        if (newValue) {
          newValue.on("value", this.onPlaysUpdate)
        }
      },
      immediate: true
    },
    gameStatusRef: {
      handler(newValue, oldValue) {
        if (oldValue) {
          oldValue.off("value", this.onGameStatusUpdate)
          this.users = {}
        }
        if (newValue) {
          newValue.on("value", this.onGameStatusUpdate)
        }
      },
      immediate: true
    },
    assignedHostRef: {
      handler(newValue, oldValue) {
        if (oldValue) {
          oldValue.off("value", this.onHostUpdate)
          this.assignedHost = null
        }
        if (newValue) {
          newValue.on("value", this.onHostUpdate)
        }
      },
      immediate: true
    },
    isSelected(value) {
      if (value === true) {
        if (this.loadingTimeout !== null) {
          clearTimeout(this.loadingTimeout)
          this.loadingTimeout = null
        }
        this.loading = false
      }
    }
  }
}
