<template>
  <v-flex d-flex class="scoreboard-card">
    <v-layout column>
      <v-flex d-flex class="game-card-body">
        <v-layout column class="game-card-layout">
          <v-flex shrink class="card-scoreboard">OVERSEE GAMES</v-flex>
          <v-flex d-flex style="overflow-y: auto; max-height: 80vh">
            <RecycleScroller
              class="scoreboard-card__recycle-scroller"
              :items="groups"
              :item-size="380"
              key-field="id"
              v-slot="{ item }"
            >
              <v-layout row>
                <AuditCard
                  class="scoreboard-card__audit-game xs4"
                  v-for="(game, i) in item.games"
                  :key="`${game.name}-${i}`"
                  :game="game"
                  :favorite="game.favorite"
                  @onEnterGame="onEnterGame"
                  @toggleFavorite="toggleFavorite"
                />
              </v-layout>
            </RecycleScroller>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import _ from "lodash"
import AuditCard from "@/components/AuditCard"
import { RecycleScroller } from "vue-virtual-scroller"

import "vue-virtual-scroller/dist/vue-virtual-scroller.css"

export default {
  name: "GamesAuditor",
  components: {
    AuditCard,
    RecycleScroller
  },
  props: {
    games: {
      type: Array,
      default: () => []
    },
    tournament: {
      type: Object
    }
  },
  data() {
    return {
      favoriteArray: []
    }
  },
  created() {
    const string = this.$cookies.get("favoriteArray")
    this.favoriteArray = string ? string.split(",") : []
  },
  methods: {
    toggleFavorite(gameID) {
      if (this.favoriteArray.indexOf(gameID) >= 0) {
        this.favoriteArray.splice(this.favoriteArray.indexOf(gameID), 1)
      } else {
        this.favoriteArray.push(gameID)
      }
      this.$cookies.set("favoriteArray", this.favoriteArray)
    },
    onEnterGame(gameID) {
      this.$emit("onEnterGame", gameID)
    }
  },
  computed: {
    groups() {
      return _.chunk(this.sorted, 3).map(games => {
        const id = games.reduce((acc, val) => acc + val.id, "")
        return { games, id }
      })
    },
    favorite() {
      return this.favoriteArray.reduce((acc, val) => {
        acc[val] = true
        return acc
      }, {})
    },
    sorted() {
      const favorite = this.favorite
      return this.games
        .map(game => ({
          ...game,
          favorite: !!favorite[game.id]
        }))
        .sort((a, b) => b.favorite - a.favorite)
    }
  }
}
</script>

<style lang="scss">
.scoreboard-card {
  line-height: 1;
  background-color: $color-white;
  box-shadow: 0px 0px 8px 0px rgba($color-black, 0.5);

  &__audit-game {
    border: 1px solid #000;
    font-size: 14px;
    min-height: 270px;
  }
  .card-scoreboard {
    text-align: center;
    color: $color-white;
    font-size: 30px;
    margin: 15px;
  }
  .game-card-body {
    position: relative;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    background-color: $color-grey;
    background-size: cover;
    background-position: center;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($color-black, 0.5);
    }
    .game-card-layout {
      position: relative;
    }
  }
}
</style>
