var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: _vm.isCompleted ? "green--text" : "grey--text" }, [
      _vm._v(" " + _vm._s(_vm.teamName) + " "),
      _c(
        "span",
        { staticClass: "team-score" },
        [
          _vm.teamScore
            ? [
                _c("b", [_vm._v("-")]),
                _vm._v(" " + _vm._s(_vm.teamScore) + " pts "),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
    _c("div", [_vm._v(" " + _vm._s(_vm.lastPlay) + " ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }