var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "audit-countdown", attrs: { row: "" } },
    [
      _c(
        "v-flex",
        { attrs: { "d-flex": "" } },
        [
          _c("countdown", {
            ref: "countdown",
            attrs: { interval: 1000, time: _vm.endTime },
            on: { progress: _vm.trackProgress, end: _vm.onCountDownEnded },
          }),
          _c("div", { ref: "timer", staticClass: "timer" }, [
            _c("span", [_vm._v(_vm._s(_vm.time))]),
          ]),
        ],
        1
      ),
      _vm.showInput
        ? _c(
            "v-flex",
            { staticClass: "additional-seconds-input" },
            [
              _c("v-text-field", {
                ref: "additionalSeconds",
                attrs: { solo: "", placeholder: "+ secs", "hide-details": "" },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.updateTimerSeconds.apply(null, arguments)
                  },
                  blur: _vm.handleInputBlur,
                },
                model: {
                  value: _vm.additionalSeconds,
                  callback: function ($$v) {
                    _vm.additionalSeconds = $$v
                  },
                  expression: "additionalSeconds",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }